import React from 'react';
import styles from './styles.module.css'


const CookieAcceptButton = () => {
	return (
		<button className={styles.acceptButton}>
			Понятно
		</button>
	);
};

export default CookieAcceptButton;